import type { TypeSearchPromoBlockFields } from '@lib/generated-types'
import { getIconFromContentful } from '@lib/icons'
import { Button, Card, Container, type ContainerProps, Icon, Typography } from '@shc/ui'
import ResponsiveImage from '@components/responsive-image'
import { AnalyticsLink } from '@components/analytics'
import { richTextRenderFactory } from '@lib/rich-text-options'
import Content from '@components/content'
import NextLink from 'next/link'
import { linkAttributes } from '@lib/token-maps'

type SearchPromoBlockType = TypeSearchPromoBlockFields & ContainerProps

const InlineVariant = ({
  heading,
  headingLink,
  headingAnchorOrExternalLink,
  description,
  buttonLabel,
  buttonLink,
  buttonAnchorOrExternalLink,
  image,
}: SearchPromoBlockType) => {
  return (
    <Container>
      <div className="flex md:gap-5 lg:gap-15 items-center justify-between py-8 border-t">
        <div className="flex flex-col gap-3 justify-between">
          {headingLink || headingAnchorOrExternalLink ? (
            <AnalyticsLink
              noUnderline
              {...linkAttributes(headingLink, headingAnchorOrExternalLink)}
              className="font-medium group">
              <Typography
                as="h2"
                variant="h4"
                className="text-primary-700 group-hover:underline font-bold">
                {heading}
              </Typography>
            </AnalyticsLink>
          ) : (
            <Typography as="h2" variant="h4" className="group-hover:underline font-semibold">
              {heading}
            </Typography>
          )}
          <Content className="text-sm">{richTextRenderFactory()(description)}</Content>
          {(buttonLink || buttonAnchorOrExternalLink) && buttonLabel && (
            <AnalyticsLink
              as={Button}
              variant="outlined"
              asPassthru={NextLink}
              {...linkAttributes(buttonLink, buttonAnchorOrExternalLink)}
              className="font-semibold group mt-2 text-sm w-full md:w-fit"
              size="sm"
              width="full">
              {buttonLabel}
            </AnalyticsLink>
          )}
        </div>
        {image && (
          <div className="flex flex-col justify-end items-center">
            <ResponsiveImage
              image={{
                altTag: image.fields.image.fields.title,
                urlString: image.fields.image.fields?.file?.url,
              }}
              focalPoint="center"
              columnWidthMax="100%"
              className="md:w-[288px] md:h-[162px] lg:w-[335px] lg:h-[188px] rounded"
            />
          </div>
        )}
      </div>
    </Container>
  )
}

const SearchPromoBlock = ({
  internalName,
  variant,
  icon,
  image,
  heading,
  headingLink,
  headingAnchorOrExternalLink,
  description,
  buttonLabel,
  buttonLink,
  buttonAnchorOrExternalLink,
}: SearchPromoBlockType) => {
  if (variant === 'inline') {
    return (
      <InlineVariant
        heading={heading}
        headingLink={headingLink}
        headingAnchorOrExternalLink={headingAnchorOrExternalLink}
        description={description}
        buttonLabel={buttonLabel}
        buttonLink={buttonLink}
        buttonAnchorOrExternalLink={buttonAnchorOrExternalLink}
        image={image}
        internalName={internalName}
      />
    )
  }

  return (
    <Container className="mb-8">
      <Card padding="sm" variant="outlined">
        <div className="flex flex-row">
          <div className="bg-primary-50 rounded-full size-12 min-h-12 min-w-12 mr-4 flex items-center justify-center">
            {icon && (
              <Icon fixedWidth icon={getIconFromContentful(icon)} size="lg" color="#18808C" />
            )}
          </div>
          <div className="flex flex-col gap-1">
            <Typography variant="small-body-semibold">{heading}</Typography>
            <Content className="text-sm">{richTextRenderFactory()(description)}</Content>
          </div>
        </div>
      </Card>
    </Container>
  )
}

export default SearchPromoBlock
