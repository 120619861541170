export const getYoutubeVideoIdFromUrl = (urlString: string) => {
  try {
    console.log('greg urlString', urlString)
    const url = new URL(urlString)

    // check if v search parameter
    if (url.searchParams.has('v')) return url.searchParams.get('v') ?? undefined
    // check if /embed/ in path
    if (url.pathname.includes('/embed/')) return url.pathname.split('/').pop()
  } catch (e) {
    console.error('VideoPlayer: error getting video id from youtube url: ' + e)
  }
}
