'use client'
import { type ComponentPropsWithoutRef, useEffect, useState, useContext } from 'react'
import clsx from 'clsx'
import Accordion from '@components/accordion'
import { richTextRenderFactory } from '@lib/rich-text-options'
import type {
  TypeExpandableContentFields,
  TypeExpandableContentItemFields,
} from '@lib/generated-types'
import slugify from '@sindresorhus/slugify'
import { type Entry } from 'contentful'
import useAnalytics, { type SPContext } from '@hooks/use-analytics'
import {
  BlockContext,
  ItemContext,
  PageContext,
  PlaceContext,
  ProviderContext,
} from '@lib/analytics'
import useUrlHash from '@hooks/use-url-hash'

interface ExpandableContentProps extends TypeExpandableContentFields {
  offsetQuerySelectors?: string[]
}

const ExpandableContent = ({
  internalName,
  sectionLink,
  items,
  className,
  offsetQuerySelectors,
  ...props
}: ComponentPropsWithoutRef<'div'> & ExpandableContentProps) => {
  const richTextRenderChildren = richTextRenderFactory()

  const [targetSectionLink, setTargetSectionLink] = useState<string>('')
  const [combinedHeaderHeight, setCombinedHeaderHeight] = useState<number>(0)

  const { trackSnowplow } = useAnalytics()

  const hash = useUrlHash()

  useEffect(() => {
    const calcHeaderHeight =
      offsetQuerySelectors?.reduce((acc, selector) => {
        const element = document.querySelector<HTMLElement>(selector)
        return acc + (element?.offsetHeight ?? 0)
      }, 0) ?? 0
    setCombinedHeaderHeight(calcHeaderHeight)
  }, [offsetQuerySelectors])

  useEffect(() => {
    setTimeout(() => {
      setTargetSectionLink(hash)
    }, 1000)
  }, [hash])

  const targetOpen = (item: Entry<TypeExpandableContentItemFields>): boolean => {
    return item.fields.sectionLink === targetSectionLink
  }

  // Environment contexts
  const pageContext = useContext(PageContext)
  const placeContext = useContext(PlaceContext)
  const providerContext = useContext(ProviderContext)
  const blockContext = useContext(BlockContext)
  const itemContext = useContext(ItemContext)
  const environmentContexts: SPContext[] = [
    pageContext,
    placeContext,
    providerContext,
    blockContext,
    itemContext,
  ].filter((context) => context !== null)

  return (
    <div
      id={sectionLink ? slugify(sectionLink) : undefined}
      className={clsx('container', className)}
      {...props}>
      <div>
        {items?.map((item) => {
          const contexts: SPContext[] = [
            {
              name: 'component',
              data: {
                component_text: item.fields.title,
              },
            },
            ...environmentContexts,
          ]

          return (
            <Accordion
              style={{ scrollMarginTop: `${combinedHeaderHeight}px` }}
              key={item.sys.id}
              title={item.fields.title}
              onExpand={() =>
                trackSnowplow({
                  event: { name: 'component_expand', data: {} },
                  contexts,
                })
              }
              onCollapse={() =>
                trackSnowplow({
                  event: { name: 'component_collapse', data: {} },
                  contexts,
                })
              }
              isOpen={targetOpen(item)}
              id={item.fields.sectionLink ? slugify(item.fields.sectionLink) : undefined}>
              {richTextRenderChildren(item.fields.description)}
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

export default ExpandableContent
