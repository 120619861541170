'use client'

import omit from 'lodash/omit'
import { richTextRender } from '@lib/rich-text-base'
import type { TypeVideoPlayerFields } from '@lib/generated-types'
import {
  VideoPlayer as SclVideoPlayer,
  type VideoPlayerProps as SclVideoPlayerProps,
} from '@shc/ui'
import { type ComponentPropsWithoutRef } from 'react'
import { startYouTubeTracking } from '@snowplow/browser-plugin-youtube-tracking'
import { getYoutubeVideoIdFromUrl } from '@lib/utilities/video-utilities'

interface VideoPlayerProps extends ComponentPropsWithoutRef<'div'>, TypeVideoPlayerFields {
  showCaption?: boolean
}

const VideoPlayer = ({
  videoUrl,
  caption,
  showCaption = true,
  fullScreen,
  autoPlay,
  loop,
  className,
  ...props
}: VideoPlayerProps) => {
  const videoPlayerProps: SclVideoPlayerProps = {
    url: videoUrl,
    className,
    presentation: fullScreen,
    autoPlay,
    loop,
    ...omit(props, ['internalName']),
  }
  const videoId = getYoutubeVideoIdFromUrl(videoUrl)

  if (showCaption && caption) {
    videoPlayerProps.description = richTextRender(caption)
  }

  return (
    <SclVideoPlayer
      iframeId={videoId}
      {...videoPlayerProps}
      onReady={() => {
        if (videoId) {
          startYouTubeTracking({
            id: videoId,
            video: videoId,
            captureEvents: ['play', 'pause', 'end', 'percent_progress', 'error'],
          })
        } else {
          console.error('Cannot find YouTube video ID to track analytics')
        }
      }}
    />
  )
}

export default VideoPlayer
