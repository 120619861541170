import type { ComponentPropsWithoutRef } from 'react'
import CardLong from '@components/card-long'
import { contentTypeBasePathMap } from '@lib/constants'
import type { TypeCaptionedMedia } from '@lib/generated-types'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import { colWidthCenterMap } from '@lib/token-maps'
import clsx from 'clsx'
import CaptionedMedia from './captioned-media'
import Analytics from '@components/analytics'

// I have concerns about this type, it is the same name as one exported from generated-types - GJ
interface TypeNewsArticle extends ComponentPropsWithoutRef<'div'> {
  title: string
  summary: string
  image: TypeCaptionedMedia
  slug: string
}

const NewsArticle = ({ title, summary, image, slug, className }: TypeNewsArticle) => {
  const link = contentTypeBasePathMap['newsArticle'] + slug
  return (
    <div
      className={clsx(
        colWidthCenterMap['8 columns'],
        'flex flex-row flex-wrap gap-8 md:gap-12',
        className
      )}>
      <Analytics
        snowplow={{
          contexts: [
            {
              name: 'component',
              data: {
                component_text: title,
                component_url: link,
              },
            },
          ],
        }}>
        <CardLong
          title={title}
          summary={summary}
          image={
            <CaptionedMedia
              {...image.fields}
              aspectRatio="4:3"
              className="rounded md:rounded-none md:rounded-l overflow-hidden"
              imageClassName={clsx(
                'w-full',
                'rounded',
                'md:min-w-[286px]',
                'md:w-[286px]',
                'md:rounded-none',
                'md:rounded-l',
                'scale-100',
                'duration-200',
                'group-hover:scale-105',
                'group-hover:origin-center',
                'group-hover:duration-200'
              )}
              extras={generateBaseMediaQWidths(4)}
              showCaption={false}
            />
          }
          link={link}
          className="group rounded w-full md:drop-shadow-md md:bg-gray-50"
        />
      </Analytics>
    </div>
  )
}

export default NewsArticle
